<template>
  <div>
    <!--      <div class="hm_view_nav">
      <a href="javascript:;" class="sec_newewm hbzd">海报<em class="iconfont icon-jinrujiantou f12"></em></a>
    </div> -->
    <header class="x_header bgcolor_11 cl f15">
      <a class="z f14" @click="toSelect">
        <i class="iconfont icon-fanhuijiantou w15"></i>返回
      </a>
      <a class="y sidectrl view_ctrl"><i class="iconfont icon-fenlei f14"></i></a>
      <div class="navtitle">快乐建家房产中心</div>
    </header>
    <div class="x_header_fix"></div>
    <div class="none view_tools_mask"></div>

    <div class="list" style="margin-bottom: 40px">
      <nav id="nav" style="position: fixed; top: 42px; left: 0; z-index: 999">
        <!-- 按照区域查询 -->
        <div @click="chooseRegion = true;menuselect001('0');">
          <span class="a_down on">{{ search1_title }}</span>
        </div>

        <i class="l"></i>
        <div @click="menuselect001('2')" :class="userclick == '2' ? 'on' : ''">
          <span class="a_down">{{ search2_title }}</span>
          <ol>
            <li @click.stop="search2('')"><a :class="typeid == '' ? 'selected' : ''">不限</a></li>
            <li @click.stop="search2('1')"><a :class="typeid == '1' ? 'selected' : ''">出租</a></li>
            <li @click.stop="search2('0')"><a :class="typeid == '0' ? 'selected' : ''">出售</a></li>
          </ol>
        </div>

        <i class="l"></i>
        <div @click="menuselect001('3')" :class="userclick == '3' ? 'on' : ''">
          <span class="a_down">{{ search3_title }}</span>
          <ol>
            <li @click.stop="search3('')"><a :class="price_begin == '' ? 'selected' : ''">不限</a></li>
            <li @click.stop="search3('1')"><a :class="price_begin == '1' ? 'selected' : ''">100万以下</a></li>
            <li @click.stop="search3('2')"><a :class="price_begin == '100' ? 'selected' : ''">100万以上</a></li>
          </ol>
        </div>

        <i class="l"></i>
        <div @click="menuselect001('4')" :class="userclick == '4' ? 'on' : ''">
          <span class="a_down">{{ search4_title }}</span>
          <ol>
            <li @click.stop="search4('')"><a :class="catid == '' ? 'selected' : ''">不限</a></li>
            <li @click.stop="search4('1')">
              <a :class="catid == '1' ? 'selected' : ''">住宅</a>
            </li>
            <li @click.stop="search4('2')">
              <a :class="catid == '2' ? 'selected' : ''">公寓</a>
            </li>
            <li @click.stop="search4('3')">
              <a :class="catid == '3' ? 'selected' : ''">商铺</a>
            </li>
            <li @click.stop="search4('4')">
              <a :class="catid == '4' ? 'selected' : ''">写字楼</a>
            </li>
            <li @click.stop="search4('5')">
              <a :class="catid == '5' ? 'selected' : ''">厂房</a>
            </li>
            <li @click.stop="search4('6')">
              <a :class="catid == '6' ? 'selected' : ''">农民房</a>
            </li>
            <li @click.stop="search4('7')">
              <a :class="catid == '7' ? 'selected' : ''">其它</a>
            </li>
          </ol>
        </div>

        <div @click="menuselect001('5')" :class="userclick == '5' ? 'on' : ''">
          <span class="a_down">更多</span>
          <ol>
            <li @click="menuselect002($event)">
              <a>户型</a>
              <ul>
                <li @click.stop="search5('')"><a :class="room == '' ? 'selected' : ''">不限</a></li>
                <li @click.stop="search5('1')"><a :class="room == '1' ? 'selected' : ''">一室</a></li>
                <li @click.stop="search5('2')"><a :class="room == '2' ? 'selected' : ''">二室</a></li>
                <li @click.stop="search5('3')"><a :class="room == '3' ? 'selected' : ''">三室</a></li>
                <li @click.stop="search5('4')"><a :class="room == '4' ? 'selected' : ''">四室</a></li>
                <li @click.stop="search5('5')"><a :class="room == '5' ? 'selected' : ''">五室</a></li>
              </ul>
            </li>
            <li @click="menuselect002($event)">
              <a>房龄</a>
              <ul>
                <li @click.stop="search6('')"><a :class="houseyear == '' ? 'age-list selected' : 'age-list'">不限</a></li>
                <li @click.stop="search6('1')"><a :class="houseyear == '1' ? 'age-list selected' : 'age-list'">10年以下</a>
                </li>
                <li @click.stop="search6('2')"><a :class="houseyear == '2' ? 'age-list selected' : 'age-list'">10年以上</a>
                </li>
              </ul>
            </li>
            <li @click="menuselect002($event)">
              <a href="javascript:">朝向</a>
              <ul>
                <li @click.stop="search7('')"><a
                    :class="toward == '' ? 'roomFace-list selected' : 'roomFace-list'">不限</a></li>
                <li @click.stop="search7('1')"><a
                    :class="toward == '1' ? 'roomFace-list selected' : 'roomFace-list'">东</a></li>
                <li @click.stop="search7('2')"><a
                    :class="toward == '2' ? 'roomFace-list selected' : 'roomFace-list'">南</a></li>
                <li @click.stop="search7('3')"><a
                    :class="toward == '3' ? 'roomFace-list selected' : 'roomFace-list'">西</a></li>
                <li @click.stop="search7('4')"><a
                    :class="toward == '4' ? 'roomFace-list selected' : 'roomFace-list'">北</a></li>
                <li @click.stop="search7('5')"><a
                    :class="toward == '5' ? 'roomFace-list selected' : 'roomFace-list'">东南</a></li>
                <li @click.stop="search7('6')"><a
                    :class="toward == '6' ? 'roomFace-list selected' : 'roomFace-list'">西南</a></li>
                <li @click.stop="search7('7')"><a
                    :class="toward == '7' ? 'roomFace-list selected' : 'roomFace-list'">南北</a></li>
                <li @click.stop="search7('8')"><a
                    :class="toward == '8' ? 'roomFace-list selected' : 'roomFace-list'">东北</a></li>
                <li @click.stop="search7('9')"><a
                    :class="toward == '9' ? 'roomFace-list selected' : 'roomFace-list'">西北</a></li>
                <li @click.stop="search7('10')"><a
                    :class="toward == '10' ? 'roomFace-list selected' : 'roomFace-list'">东西</a></li>
              </ul>
            </li>
            <!--            <li>
              <a href="javascript:">装修</a>
              <ul>
                <li>
                  <a  class="fitment-list selected">不限</a>
                </li>
                <li>
                  <a  class="fitment-list  ">毛坯</a>
                </li>
                <li>
                  <a  class="fitment-list  ">精装</a>
                </li>
                <li>
                  <a  class="fitment-list  ">简装</a>
                </li>
                <li>
                  <a  class="fitment-list  ">豪华装修</a>
                </li>
              </ul>
            </li> -->
            <!--            <li>
              <a href="javascript:">面积</a>
              <ul>
                <li>
                  <a  class="original-list selected">不限</a>
                </li>
                <li>
                  <a  class="original-list ">89平米及以下</a>
                </li>
                <li>
                  <a  class="original-list ">89平米以上</a>
                </li>
              </ul>
            </li> -->

            <li @click="menuselect002($event)">
              <a href="javascript:">排序</a>
              <ul>
                <li @click.stop="search8('0')"><a
                    :class="orderby == '0' ? 'sequence-list selected' : 'sequence-list'">默认排序</a></li>
                <li @click.stop="search8('1')"><a
                    :class="orderby == '1' ? 'sequence-list selected' : 'sequence-list'">发布时间由近到远</a></li>
                <li @click.stop="search8('2')"><a
                    :class="orderby == '2' ? 'sequence-list selected' : 'sequence-list'">总价由低到高</a></li>
                <li @click.stop="search8('3')"><a
                    :class="orderby == '3' ? 'sequence-list selected' : 'sequence-list'">总价由高到低</a></li>
                <!--                <li @click.stop="search8('4')"><a class="sequence-list">单价由低到高</a></li>
                <li @click.stop="search8('5')"><a class="sequence-list">面积由高到低</a></li>
                <li @click.stop="search8('6')"><a class="sequence-list">面积由低到高</a></li> -->
              </ul>
            </li>
          </ol>
        </div>
      </nav>
    </div>

    <div class="demo-block">
      <div class="ui-searchbar-wrap" style="background-color: #f4f4f4">
        <div class="ui-searchbar ui-border-radius">
          <i class="ui-icon-search"></i>
          <div class="ui-searchbar-text ui-txt-tips" style="width: 100%; border: 0; background: 0 0">
            <input type="text" placeholder="输入小区名称试试" autocapitalize="off" v-model="searchtxt"
              @keyup.enter="getHouseList" style="width: 100%; border: 0; background: 0 0" />
          </div>
          <!--     <div class="ui-searchbar-input">
            <input name="mod" type="hidden" value="search">

          </div> -->
          <i class="ui-icon-close"></i>
        </div>
        <!-- <button class="ui-searchbar-cancel">取消</button> -->
      </div>
    </div>

    <div id="components-demo" class="wp">
      <ul class="ul-list1-hlg" id="house_list" v-for="item in house_list" :id="item.id">
        <li class="istradefalse house_info" data-href="house.html?id=1" @click="toDetails(item.id)">
          <div class="pic">
            <a>
              <img :src="item.img_url" alt="" />
              <em class="start displayfalse"></em>
            </a>
          </div>
          <div class="txt">
            <h3 class="first">
              <a>
                <em class="e1 displaytrue"></em>
                <font style="">{{ item.housename }}</font>
              </a>
            </h3>
            <p class="t1">
              {{ item.room }}室{{ item.hall }}厅{{ item.toilet }}卫 /
              {{ item.housearea }}㎡
            </p>
            <div class="info info0">
              <div class="left">
                <span>{{item.typetitle}}</span><span>{{ item.add_date }}</span>
              </div>
              <p class="right">{{ item.price }}{{item.typeid=='1' ? '元' : '万元'}}</p>
            </div>
          </div>
          <i class="suc displayfalse"></i> <em class="bao displayfalse"></em>
        </li>
      </ul>
    </div>
    <div class="demo-block"></div>

    <div class="bg_1"></div>
    <van-popup v-model="chooseRegion" round position="bottom">
      <van-cascader v-model="chooseRegion" title="请选择区域" :options="quxianJson" @close="chooseRegion = false;"
        @change="chooseRegionChange" @finish="chooseRegion = false;" />
    </van-popup>
  </div>
</template>

<script>
  import {
    Popup,
    Cascader,
    Toast
  } from "vant";
  import "../../../public/css/info-mb.css";
  import "../../../public/css/nav.css";
  import "../../../public/css/custom.css";
  import $ from "jquery";
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from "js-cookie";
  import {
    getQueryVariable
  } from "@/utils/request";
  import area from "@/utils/4-area.json";
  import wx from 'weixin-js-sdk';
  export default {
    data() {
      return {
        quxianJson: [], //获取所有4级城市的数据,加载到界面
        chooseRegion: false,
        sitenum: "",
        openid: "",
        house_list: [], //房屋列表信息
        searchtxt: "", //搜索关键字
        userclick: "", //用户点击第几个菜单
        catid: "", //房产类型  住宅 公寓 商铺  写字楼 厂房 农民房  其它
        typeid: "", //出租出售类型  0 出售 1出租
        room: "", //几室一厅
        price_begin: "", //售价范围开始
        price_end: "", //售价范围结束
        search1_title: "区域", //顶部导航文字
        search2_title: "类别", //顶部导航文字
        search3_title: "房价", //顶部导航文字
        search4_title: "类型", //顶部导航文字
        toward: "", //朝向 toward
        houseyear: "", //房龄
        orderby: "", //排序
        housename: "" //小区名字
        //houseArea: "" //查询条件,房屋所在的具体街道
      };
    },
    activated(){
      //this.getWxConfig(); //加载js
    },
    mounted() {
      //判断一下，cookie如果丢失了，那么就重新登录
      if (Cookies.get("kh") == "" || Cookies.get("kh") == null) {
        //如果获取用户信息失败那就直接跳到登录
        this.$router.push("login");
        return	;
      }
      this.quxianJson = this.parseArea();
      this.getHouseList(); //获取正常状态的房屋信息
      this.getWxConfig(); //加载js
    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
            ]
          });
          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页', //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！', //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' + Cookies.get("kh"), //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png' //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
          });

        } else {
          Toast(result.msg);
        }
      },

      parseArea() {
        //获取所有4级城市的数据,加载到界面
        //console.log(area);
        //console.log(area instanceof Array);
        let areaJs = JSON.parse(JSON.stringify(area));
        //console.log(areaJs instanceof Array);
        let result = areaJs.map(item => {
          return {
            text: item.pro_name,
            value: item.pro_code,
            children: item.pro_cities.map(e => {
              return {
                text: e.city_name,
                value: e.city_code,
                children: e.city_areas.map(val => {
                  return {
                    text: val.area_name,
                    value: val.area_code,
                    children: val.area_streets.map(t => {
                      return {
                        text: t.street_name,
                        value: t.street_code
                      }
                    })
                  }
                })
              }
            })
          }
        })
        return result;
      },
      chooseRegionChange({
        selectedOptions
      }) {
        let areaName = selectedOptions.map((option) => option.text).join('/');
        let areaCode = selectedOptions[selectedOptions.length - 1].value;
        console.log("=====================>" + areaName);
        //  console.log(areaCode);
        this.choosedRegion = areaName; //这个房屋所在的具体省市区县街道
        this.search1_title = areaName.substring(0, 4);
        this.getHouseList(); //获取正常状态的房屋信息
      },
      toDetails(id) {
        this.$router.push({
          name: "house_details",
          type: "edit",
          query: {
            id: id,
          },
        });
      },
      toSelect() {
        this.$router.push({
          name: "select",
        });
      },
      search1(type) {
        //顶部搜索查询  省市区的选择
        this.userclick = "";
        console.log(type);
        this.getHouseList(); //获取正常状态的房屋信息
      },
      search2(type) {
        //顶部搜索查询  出租出售类型的搜索
        switch (type) {
          case "0":
            //执行代码块 1
            this.search2_title = "出售";
            break;
          case "1":
            //执行代码块 2
            this.search2_title = "出租";
            break;
          default:
            //与 case 1 和 case 2 不同时执行的代码
            this.search2_title = "类型";
        }
        this.typeid = type;
        this.userclick = "";

        console.log(type);
        this.getHouseList(); //获取正常状态的房屋信息
      },
      search3(type) {
        //顶部搜索查询  出租出售类型的搜索
        switch (type) {
          case "1":
            //执行代码块 1
            this.price_begin = "1"; //售价范围开始
            this.price_end = "100"; //售价范围结束
            this.search3_title = "100万以下";
            break;
          case "2":
            //执行代码块 2
            this.price_begin = "100"; //售价范围开始
            this.price_end = "9999"; //售价范围结束
            this.search3_title = "100万以上";
            break;
          default:
            //与 case 1 和 case 2 不同时执行的代码
            this.price_begin = ""; //售价范围开始
            this.price_end = ""; //售价范围结束
            this.search3_title = "房价";
        }

        this.userclick = "";
        console.log("  this.price_begin:" + this.price_begin);
        this.getHouseList(); //获取正常状态的房屋信息
      },
      search4(type) {
        //顶部搜索查询  房屋类型的搜索  民宅 厂房 农民房的搜索
        switch (type) {
          case "0":
            this.search4_title = "类型";
            break;
          case "1":
            this.search4_title = "住宅";
            break;
          case "2":
            this.search4_title = "公寓";
            break;
          case "3":
            this.search4_title = "商铺";
            break;
          case "4":
            this.search4_title = "写字楼";
            break;
          case "5":
            this.search4_title = "厂房";
            break;
          case "6":
            this.search4_title = "农民房";
            break;
          case "7":
            this.search4_title = "其它";
            break;
          default:
            //与 case 1 和 case 2 不同时执行的代码
            this.search4_title = "类型";
        }
        this.catid = type;
        this.userclick = "";
        this.getHouseList(); //获取正常状态的房屋信息
      },
      search5(type) {
        //顶部搜索查询  几室一厅
        this.room = type;
        this.userclick = "";
        this.getHouseList(); //获取正常状态的房屋信息
      },
      search6(type) {
        //顶部搜索查询  房龄
        this.houseyear = type;
        this.userclick = "";
        this.getHouseList(); //获取正常状态的房屋信息
      },
      search7(type) {
        //顶部搜索查询 朝向
        this.toward = type;
        this.userclick = "";
        this.getHouseList(); //获取正常状态的房屋信息
      },
      search8(type) {
        //顶部搜索查询  排序
        this.orderby = type;
        this.userclick = "";
        this.getHouseList(); //获取正常状态的房屋信息
      },
      menuselect002(event) {
        let target = event.target;
        //不知道为什么这里取的是点击的下级,所以用$(target).parent().获取上级
        $(target)
          .parent()
          .toggleClass("on")
          .siblings()
          .filter(".on")
          .removeClass("on");
      },
      menuselect001(index) {
        console.log("当前点击的索引================>" + index);
        this.userclick = index;
        if (index == "0") {
          this.choosedRegion = ""; //这个房屋所在的具体省市区县街道
          this.search1_title = "区域";
          this.getHouseList(); //获取正常状态的房屋信息
        }
      },
      toAdd(id) {
        this.$router.push({
          name: "myHouse",
          type: "add",
          query: {
            id: id,
          },
        });
      },
      async getHouseList() {
        //获取正常状态的房屋信息
        var that = this;
        var uri = "";
        that.house_list = [];
        uri = this.$global.domain + "/tools/postmysql.ashx";
        var param = {
          act: "getHouseList",
          type: "all",
          searchtxt: that.searchtxt, //输入的搜索内容
          catid: that.catid,
          typeid: that.typeid,
          price_begin: that.price_begin, //售价范围开始
          price_end: that.price_end, //售价范围结束
          room: that.room,
          houseyear: that.houseyear,
          orderby: that.orderby,
          toward: that.toward,
          houseArea: that.choosedRegion, //这个房屋所在的具体城市街道
          user: Cookies.get("kh"),
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          let data = result.data;
          if (data.length > 0) {
            for (var i = 0; i < data.length; i++) {
              var sData = {
                id: data[i][0],
                img_url: data[i][2],
                title: data[i][1],
                add_date: data[i][9],
                price: data[i][3],
                housearea: data[i][8],
                room: data[i][4],
                hall: data[i][5],
                toilet: data[i][6],
                balcony: data[i][7],
                typeid: data[i][11],
                typetitle: data[i][12],
                housename: data[i][13],
                // kh: data[i].kh,
              };
              that.house_list.push(sData);
            }
          }
        } else {
          this.$router.push("login");
        }
      },
    },

    components: {},
  };
</script>

<style>
  .bottomBtn {
    position: relative;
    margin: 22px auto 90px auto;
    width: 354px;
    height: 54px;
    line-height: 54px;
    border-radius: 40px;
    background-color: #ea2e3a;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  .demo-block {
    position: relative;
  }

  .ui-searchbar-wrap {
    display: -webkit-box;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    background-color: #ebeced;
    height: 44px;
  }

  .ui-searchbar.ui-border-radius {
    border-radius: 5px;
  }

  .ui-searchbar {
    border-radius: 5px;
    margin: 0 10px;
    background: #fff;
    height: 30px;
    line-height: 30px;
    position: relative;
    padding-left: 4px;
    -webkit-box-flex: 1;
    display: -webkit-box;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    color: #bbb;
    width: 100%;
  }

  .ui-searchbar-wrap .ui-searchbar-cancel {
    color: #00a5e0;
    font-size: 16px;
    padding: 4px 8px;
  }
</style>
